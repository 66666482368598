<template>
  <div>
    <nav-bar class="detail-nav">
      <div class="back" slot="left">
        <img slot="left" />
      </div>
      <div class="title" slot="center">
        <div slot="center">登录</div>
      </div>
    </nav-bar>
    <div class="content">
      <div class="logo">
        <img src="~assets/images/common/logo_huanChuang.png" />
      </div>
      <div class="inputBody" v-show="way == 1">
        <div class="input">
          <img src="../../assets/images/login/phone.png" />
          <div class="xieLine"></div>
          <input type="text" v-model="phone" placeholder="请输入手机号码" />
        </div>

        <div class="yanzheng">
          <div class="input" style="width: 70%; border-radius: 5px 0px 0px 5px">
            <img src="../../assets/images/login/capture.png" />
            <div class="xieLine"></div>
            <input type="text" v-model="capture" placeholder="请输入验证码" />
          </div>
          <div v-show="show" @click="getCode" class="right">获取验证码</div>
          <div v-show="!show" class="right code">
            <span>{{ count }}s</span>
          </div>
        </div>
      </div>

      <div class="inputBody" v-show="way == 2">
        <div class="input">
          <img src="../../assets/images/login/phone.png" />
          <div class="xieLine"></div>
          <input type="text" v-model="name" placeholder="请输入姓名" />
        </div>
        <div class="input">
          <img src="../../assets/images/login/capture.png" />
          <div class="xieLine"></div>
          <input type="text" v-model="shenfen" placeholder="请输入身份证" />
        </div>
        <!-- <div class="yanzheng">
            <!-- <div class="input" style="width:70%; border-radius: 5px 0px 0px 5px" >
          <img src="../../assets/images/login/capture.png">
             <div class="xieLine"></div> <input type="text"  v-model="capture" placeholder="请输入验证码" />
        
         </div> -->
        <!-- <div  v-show="show" @click="getCode" class="right">获取验证码2</div>
             <div v-show="!show"  class="right code"> <span>{{count}}s</span> </div> -->
        <!-- </div> -->
      </div>

      <div class="bt">
        <van-button class="login" @click="commit">登录</van-button>
      </div>
      <div class="shen" v-show="way == 1" @click="shenLogin">
        使用身份证登录
      </div>
      <div class="shen" v-show="way == 2" @click="shenLogin">
        使用手机号码登录
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "common/navbar/NavBar";
import { getCapture, login, user, login2 } from "network/login";
import {
  Cell,
  CellGroup,
  Field,
  Button,
  DatetimePicker,
  Popup,
  Picker,
  Toast,
} from "vant";
export default {
  data() {
    return {
      count: "",
      show: true,
      timer: "",
      phone: "",
      capture: "",
      way: 1,
      name: "",
      shenfen: "",
    };
  },
  components: {
    NavBar,
    [Field.name]: Field,
    [CellGroup.name]: CellGroup,
    [Button.name]: Button,
  },
  methods: {
    shenLogin() {
      console.log("kkk");
      if (this.way == 1) {
        this.way = 2;
      } else {
        this.way = 1;
      }
    },
    getCode() {
      if (!this.phone) {
        Toast.fail("手机号码不能为空");
        return;
      }
      getCapture(this.phone).then((res) => {
        console.log(res, "ff");

        this.verification_key = res.key;

        const TIME_COUNT = 60;
        if (!this.timer) {
          this.count = TIME_COUNT;
          this.show = false;
          this.timer = setInterval(() => {
            if (this.count > 0 && this.count <= TIME_COUNT) {
              this.count--;
            } else {
              this.show = true;
              clearInterval(this.timer);
              this.timer = null;
            }
          }, 1000);
        }
      });
    },
    commit() {
      console.log(";ll", this.way == 1);
      if (this.way == 2) {
        if (!this.name) {
          Toast.fail("姓名不能为空");
          return;
        }
        if (!this.shenfen) {
          Toast.fail("身份证号码不能为空");
          return;
        }
        login2(this.name, this.shenfen).then((res) => {
          console.log("res", res);
          localStorage.setItem("token", res.access_token);
          if (res.access_token) {
            user().then((res) => {
              if (res.id_card) {
                this.$router.push("/profile");
                Toast.success("登录成功");
              } else {
                this.$router.push("/identityBinding");
              }
            });
          }
          //   console.log(res,'f')
        });
      } else {
        if (!this.phone) {
          Toast.fail("手机号码不能为空");
          return;
        }
        if (!this.capture) {
          Toast.fail("验证码不能为空");
          return;
        }

        //  user().then(res=>{

        //      localStorage.setItem('user', JSON.stringify(res));
        //       if(res.id_card){
        //          this.$router.push('/profile')
        //         }else{
        //          this.$router.push('/identityBinding')
        //         }

        //     })

        login(this.phone, this.verification_key, this.capture).then((res) => {
          localStorage.setItem("token", res.access_token);
          if (res.access_token) {
            user().then((res) => {
              if (res.id_card) {
                this.$router.push("/profile");
                Toast.success("登录成功");
              } else {
                this.$router.push("/identityBinding");
              }
            });
          }
          //   console.log(res,'f')
        });
      }
    },
  },
  created() {
    // user().then(res=>{
    //     console.log('res',res)
    // })
    // login
  },
};
</script>

<style scoped>
.input input {
  width: 100%;
}
.code {
  color: rgb(219, 76, 10) !important;
  /* font-size: 15px !important; */
  line-height: 49px;
  text-align: center;
}

.code span {
  font-size: 18px;
  /* margin-right: 3px; */
  font-weight: 400;
}
.yanzheng {
  display: flex;
}
.shen {
  text-align: right;
  font-size: 14px;
  color: cornflowerblue;
  margin-right: 20px;
  margin-top: 20px;
}
.bt {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}
.login {
  width: 90%;
  margin: 0 auto;
  color: #fff;
  /* background-color:  */
  background-image: linear-gradient(
    to right,
    rgb(243, 70, 31),
    rgb(243, 40, 71)
  );
  /* background-color: #f00 */
}
.right {
  background-color: rgb(253, 183, 10);
  height: 47px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  color: #333;
  font-size: 15px;
  margin-top: 25px;
  width: 40%;
  border-radius: 0 5px 5px 0;
  /* 第一个值为左上角，第二个值为右上角，第三个值为右下角，第四个值为左下角。 */
}
.input {
  margin-top: 25px;
  /* border: 1px solid #ddd; */
  /* padding: 30px */
  display: flex;
  padding-left: 12px;
  background-color: #fff;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 5px;
}
.input img {
  width: 24px;
  height: 24px;
  margin-right: 14px;
}
.xieLine {
  margin-right: 2px;
  padding: 0.5px;
  /* background-color: aqua; */
  /* width: 0.8px; */
  height: 25px;
  background-color: #ccc;
  /* Rotate div */
  transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  /* Internet Explorer */
  -moz-transform: rotate(0deg);
  /* Firefox */
  -webkit-transform: rotate(0deg);
  /* Safari 和 Chrome */
  -o-transform: rotate(0deg);
  /* Opera */
}
input {
  background-color: transparent;
  border: none;
  margin-left: 10px;
}
.inputBody {
  width: 90%;
  margin: 0 auto;
}
/* input::input-placeholder { text-align: 'center' } */
.inputtype >>> .van-cell__value {
  display: flex;
}
.logo img {
  width: 90px;
  height: 90px;
}
.logo {
  display: flex;
  justify-content: center;
}
.content {
  margin-top: 20px;
}

.item {
  display: flex;
  /* justify-content: space-between */
}
.item :first-child {
  margin-right: 16px;
  /* display: flex; */
  /* justify-content: space-between */
}
.commit {
  position: fixed;
  bottom: 0;
  width: 100%;
}
.back {
  /* background-color: aquamarine; */
  align-content: center;
  height: 55px;
  line-height: 55px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>